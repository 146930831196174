<template>
  <div class="app-sidebar">
    <div class="logo" style="padding-bottom: 25px">
      <a
        href="javascript:void(0);"
        @click="$router.push('/')"
        class="logo-icon"
      ></a>
    </div>
    <div class="app-menu" style="padding-top: 0px">
      <ul class="accordion-menu">
        <div
          v-if="battery.show"
          class="form-group text-center"
          style="width: 100%; padding-bottom: 10px"
        >
          <Battery
            :level="battery.level"
            :last_updated="battery.updated_at"
            style="margin-left: auto; margin-right: auto"
          ></Battery>
        </div>
        <div class="form-group" style="padding: 0px 30px">
          <Multiselect
            v-model="selectedClient"
            :class="{ 'multi-dark': $store.state.auth.status.dark }"
            mode="single"
            placeholder="Select a client"
            :close-on-select="false"
            :searchable="true"
            :create-option="false"
            :multiple="false"
            :options="clients"
            :allow-empty="false"
            label="name"
            track-by="id"
            name="client"
            @select="selectClient"
            id="sidebar-dropdown"
          />
        </div>
        <li class="sidebar-title">{{ $t("navigation.side.navigation") }}</li>
        <template v-for="menuItem in filteredMenuItems" :key="menuItem.id">
          <router-link
            v-if="menuItem && menuItem.subMenu.length === 0"
            :to="menuItem.route"
            custom
            v-slot="{ href, navigate, isExactActive }"
          >
            <li :class="[isExactActive && 'active-page']" :id="menuItem.id">
              <a
                :href="href"
                @click="navigate"
                :class="[isExactActive && 'active']"
              >
                <i class="material-icons-two-tone">{{ menuItem.icon }}</i
                >{{ $t(menuItem.label) }}
              </a>
            </li>
          </router-link>
          <li v-else-if="menuItem" :id="menuItem.id">
            <a href="javascript:void(0);">
              <i class="material-icons-two-tone">{{ menuItem.icon }}</i
              >{{ $t(menuItem.label) }}
              <i class="material-icons has-sub-menu">keyboard_arrow_right</i>
            </a>
            <ul class="sub-menu">
              <li v-for="subItem in menuItem.subMenu" :key="subItem.route">
                <router-link
                  :to="subItem.route"
                  v-if="subItem && (!subItem.can || $can(subItem.can))"
                  custom
                  v-slot="{ href, navigate, isExactActive }"
                >
                  <li :class="[isExactActive && 'active-page']">
                    <a
                      :href="href"
                      @click="navigate"
                      :class="[isExactActive && 'active']"
                    >
                      {{ $t(subItem.label) }}
                    </a>
                  </li>
                </router-link>
              </li>
            </ul>
          </li>
        </template>
        <div
          v-if="$can('faces.view')"
          class="app-icons"
          style="display: flex; margin-top: 20px; padding: 0 30px; gap: 10px"
        >
          <li>
            <a
              href="https://play.google.com/store/apps/details?id=uk.co.varstechnology.face"
              target="_blank"
            >
              <img
                class="media-store"
                src="https://varstechnology.co.uk/images/gp.png"
                style="max-width: 100%"
                alt="Google Play Store"
              />
            </a>
          </li>
          <li>
            <a
              href="https://apps.apple.com/gb/app/vars-face/id6504480627"
              target="_blank"
            >
              <img
                class="media-store"
                src="https://varstechnology.co.uk/images/IOS.png"
                style="max-width: 100%"
                alt="Apple App Store"
              />
            </a>
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import UserService from "../../services/user.service";
import Multiselect from "vue-multiselect";
import axios from "axios";
import authHeader from "../../services/auth-header";
import Battery from "@/components/Battery.vue";

const MENU_ITEMS = [
  {
    id: "dashboard",
    icon: "dashboard",
    label: "navigation.side.dashboard",
    route: "/",
    can: null,
    subMenu: [],
  },
  {
    id: "claims",
    icon: "flag",
    label: "navigation.side.claims",
    route: "",
    can: "forms-view",
    subMenu: [
      {
        label: "navigation.side.new_claim",
        route: "/claims/new",
        can: null,
      },
      {
        label: "navigation.side.manage_claims",
        route: "/claims/evidence",
        can: null,
      },
    ],
  },
  {
    id: "events",
    icon: "directions_car",
    label: "navigation.side.events",
    route: "/events",
    can: "events-view",
    subMenu: [],
  },
  {
    id: "blacklist",
    icon: "gpp_bad",
    label: "navigation.side.blacklist",
    route: "",
    can: "blacklist-view",
    subMenu: [
      {
        label: "navigation.side.view_blacklist",
        route: "/blacklist",
        can: "blacklist-view",
      },
      {
        label: "navigation.side.add_new_blacklist",
        route: "/blacklist/new",
        can: "blacklist-view",
      },
    ],
  },
  {
    id: "customerservice",
    icon: "comment",
    label: "navigation.side.customer_service",
    route: "/customer-service",
    can: "vehicles-view",
    subMenu: [],
  },
  {
    id: "sites",
    icon: "place",
    label: "navigation.side.sites",
    route: "",
    can: "sites-view",
    subMenu: [
      {
        label: "navigation.side.view_sites",
        route: "/sites",
        can: "sites-view",
      },
      {
        label: "navigation.side.map",
        route: "/map",
        can: "sites-view",
      },
    ],
  },
  {
    id: "reports",
    icon: "summarize",
    label: "navigation.side.reports",
    route: "",
    can: "reports-view",
    subMenu: [
      {
        label: "navigation.side.claims",
        route: "/reports/claims",
        can: "sites-view",
      },
      {
        label: "navigation.side.faces",
        route: "/reports/faces",
        can: "faces.view",
      },
    ],
  },
  {
    id: "faces",
    icon: "face",
    label: "navigation.side.face",
    route: "",
    can: "faces.view",
    subMenu: [
      {
        label: "navigation.side.faces",
        route: "/faces",
        can: "faces.view",
      },
      {
        label: "navigation.side.incidents",
        route: "/faces/incidents",
        can: "faces.view",
      },
    ],
  },
  {
    id: "devices",
    icon: "devices",
    label: "navigation.side.register_device",
    route: "/devices",
    can: null,
    subMenu: [],
    staging: true,
  },
  {
    id: "ordering",
    icon: "shopping_cart",
    label: "navigation.side.ordering",
    route: "/ordering-pos",
    can: "ordering.pos",
    subMenu: [],
  },
  {
    id: "help",
    icon: "help",
    label: "navigation.side.help_centre",
    route: "",
    can: null,
    subMenu: [
      {
        label: "navigation.side.video_guides",
        route: "/guides",
        can: null,
      },
    ],
  },
];

export default {
  name: "Sidebar",
  components: {
    Multiselect,
    Battery,
  },
  mounted() {
    this.clients = this.$store.state.auth.user.clients;
    this.clients = this.clients.sort((a, b) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
    let selectedClients = this.clients.filter((client) => {
      return parseInt(client.id) == parseInt(this.selected_client);
    });
    this.selectedClient = selectedClients[0];

    this.checkBatteryStatus();

    axios
      .get(`https://api.varsanpr.com/api/staging/restricted/check`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.success == true) {
          this.showStaging = true;
        }
      })
      .catch((error) => {});

    this.battery.interval = setInterval(() => {
      this.checkBatteryStatus();
    }, 60000);
  },
  beforeUnmount() {
    clearInterval(this.battery.interval);
  },
  data() {
    return {
      clients: [],
      selectedClient: null,
      night: this.$store.state.auth.status.dark,
      showStaging: false,
      battery: {
        show: false,
        level: 0,
        updated_at: null,
        interval: null,
      },
    };
  },
  methods: {
    selectClient(selectedClient) {
      UserService.selectClient(selectedClient || this.clients[0])
        .then(() => {
          this.$store.dispatch("auth/selectClient", selectedClient).then(() => {
            this.$router.go(this.$router.currentRoute);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    checkBatteryStatus() {
      axios
        .get(`https://api.varsanpr.com/api/account/battery`, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data?.tablet) {
            this.battery.show = true;
            this.battery.level = response.data.tablet.battery;
            this.battery.updated_at =
              new Date(
                response.data.tablet.battery_updated_at
              ).toLocaleDateString("en-GB") +
              " " +
              new Date(
                response.data.tablet.battery_updated_at
              ).toLocaleTimeString("en-GB");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    selected_client: function () {
      return this.$store.state.auth.user.selectedClient;
    },
    filteredMenuItems() {
      return MENU_ITEMS.filter((item) => {
        const isStagingItem = item.staging ? this.showStaging : true;
        return (
          item &&
          isStagingItem &&
          (!item.can || this.$can(item.can)) &&
          (this.selectedClient !== -1 || !item.route.includes("client"))
        );
      });
    },
  },
};
</script>

<style scoped>
.app-menu {
  height: calc(100vh - 181px) !important;
}

.logo-icon {
  width: 150% !important;
  height: 60px !important;
  background-size: 66% !important;
  border-radius: 0px !important;
  background-position: center left !important;
}

.hidden-sidebar-logo .logo-icon {
  background-size: 50% !important;
  height: 44.4px !important;
}

.has-sub-menu:hover {
  display: none;
}
</style>
